.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

.primaryBackground {
    background-color: var(--primary);
    color: #fff;
  }
  
  .primaryColor {
    color: var(--body);
  }
  .topNav {
    background-color: var(--secondary);
    color: var(--body);
    padding: 0px 0px 20px 0px;
  }
  
.topNav .topNavContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.topNav a {
    color: var(--body);
    text-decoration: none;
}

.topNav a:hover {
    color: var(--body);
}

.topNav .topNav__left {
    display: flex;
    padding-top: 10px;

}

.topNav .topNav__left p,
.topNav .topNav__center p,
.topNav .topNav__right p {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--body);
    margin: 0 0 0 10px;
    padding-top: 2px;
}

.topNav__center {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
}

.topNav__right {
    display: flex;
    align-items: center;
    padding-top: 10px;
}

.searchNavBar__content {
    display: flex;
    padding: 10px 0px;
    justify-content: space-between;
}

.searchNavBar__content .searchNavBar__content__search {
    margin-left: 30px;
    margin-top: 8px;
}

.searchNavBar__content .searchNavBar__content__accountIcon {
    width: 120px;
    border-radius: 4px;
    border: solid 1px #e0f4f3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    margin: 10px 20px 0px 20px;
    padding: 15px;
}

.searchNavBar__content .searchNavBar__content__accountIcon span {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--primary);
}

.navbar {
    display: flex;
    height: 70px;
    align-items: center;
}

.navbar_category_bar {
    display: flex;
    justify-content: space-between;
    color: var(--primary);
}

.navbar_links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    margin-top: 0px;
    height: 70px;
    vertical-align: middle;
}

.navbar_links .navbar-link {
    color: #707070;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    display: flex;
    align-items: center;
}

.navbar-link .navbar-link.active {
    color: var(--primary);
}

.navbar_links .phone_bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar_links .phone_bar .link__icon {
    margin-right: 10px;
    color: var(--primary) !important;
}

.navbar-link__text {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--body);
    margin-right: 20px;
}

.banner__content img {
    width: 460px;
    height: 250px;
    object-fit: contain;
}


.productItem {
    border-radius: 15px;
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    margin-bottom: 15px;
}
.productItemAllProduct {
    height: 339px;
    padding: 20px 25px 10px 26px;
    border-radius: 15px;
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    margin-bottom: 15px;
}

.productItem:hover,.productItemAllProduct:hover  {
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.productItem:first-child {
    margin-left: 0;
}

.productItem:nth-child(3n+1) {
    margin-left: 20px;
}

.productItem:nth-child(2n+1) {
    margin-left: 20px;
}

.productItem:nth-child(1n+1) {
    margin-left: 20px;
}

.products__content__header {
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px 0;
    flex-grow: 1;
}

.products__content__header .product_category {
    color: #707070;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;

}

.products__content__header__view {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    display: flex;
    width:57px;
    text-wrap: nowrap;
}

.product__image img {
    height: 200px;
    width: 200px;
    object-fit: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.product__details .product__name {
    color: #343434;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 10px 0 10px;
    text-align: left;
}

.product__details .product__price__normal {
    color: #ef0b0b;
    font-size: 9px;
    text-decoration: line-through;
    font-stretch: normal;
    font-style: normal;
}
.product_view_more {
    display: flex;
    justify-content: space-between;

}

.product__price {
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.cancel-button {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.08;
    letter-spacing: normal;
    text-align: left;
    color: var(--body);
}

.apply-button {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.08;
    letter-spacing: normal;
    text-align: right;
    color: var(--primary);
}
.footer{
    background-color: var(--secondary);
}
body {
    margin: 0;
    font-family: var(--body-font, 'Poppins, sans-serif');
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: var(--heading-font, 'Poppins, sans-serif');
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  }
  
  * {
    font-family: var(--body-font, 'Poppins, sans-serif');
  }
  